<template>
  <div :class="cardClass">
    <div class="product__item">
      <div
        class="product__item__pic set-bg"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
       <p hidden>{{ productID }}</p>
        <span class="label">New</span>
        <ul class="product__hover">
          <li>
            <a href="#"><img src="img/icon/heart.png" alt="" /></a>
          </li>
          <li>
            <a href="#"
              ><img src="img/icon/compare.png" alt="" /> <span>Compare</span></a
            >
          </li>
          <li>
            <a href="#"><img src="img/icon/search.png" alt="" /></a>
          </li>
        </ul>
      </div>
      <div class="product__item__text">
        <h6>{{ productDescription }}</h6>
        <a href="#" class="add-cart" @click="addCart(productID, productDescription, productPrice, backgroundImage);">+ Add To Cart</a>
        <div class="rating">
          <i class="fa fa-star-o"></i>
          <i class="fa fa-star-o"></i>
          <i class="fa fa-star-o"></i>
          <i class="fa fa-star-o"></i>
          <i class="fa fa-star-o"></i>
        </div>
        <h5>${{ productPrice }}</h5>
        <div class="product__color__select">
          <label for="pc-1">
            <input type="radio" id="pc-1" />
          </label>
          <label class="active black" for="pc-2">
            <input type="radio" id="pc-2" />
          </label>
          <label class="grey" for="pc-3">
            <input type="radio" id="pc-3" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
export default {
  props: {
    productID: {
      type: String
    },
    backgroundImage: {
      type: String,
      default: 'img/product/_JBP6711.jpg'
    },
    productDescription: {
      type: String,
      default: 'Simaza fashion Design'
    },
    productPrice: {
      type: String,
      default: '67.24'
    },
    cardClass: {
      type: String,
      default: 'col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix new-arrivals'
    }
  },
  methods: {
    addCart (productID, productDescription, productPrice, backgroundImage) {
      const existingCart = JSON.parse(localStorage.getItem('cart')) || []

      existingCart.push({
        id: productID,
        name: productDescription,
        quantity: 1,
        price: productPrice,
        total_price: productPrice,
        productImage: backgroundImage,
        discount: '0'
      })

      localStorage.setItem('cart', JSON.stringify(existingCart))
      this.getProductCarted()
      Swal.fire('Product Added to Cart', 'Product Added Successfully to Cart', 'success')
    },
    getProductCarted () {
      const existingCart = JSON.parse(localStorage.getItem('cart')) || []
      this.carts = existingCart
      const totalSum = existingCart.reduce((sum, item) => sum + (item.total_price * 1), 0)
      const totalQuantity = existingCart.reduce((count, item) => count + (item.quantity * 1), 0)
      const totalItem = existingCart.length

      localStorage.setItem('subQuantity', totalQuantity)
      localStorage.setItem('subTotal', totalSum)

      this.total_quantity = totalQuantity
      this.total_item = totalItem

      this.subTotal = totalSum
    }
  }
}
</script>
