<template>
  <!-- Offcanvas Menu Begin -->
  <div class="offcanvas-menu-overlay"></div>
  <div class="offcanvas-menu-wrapper">
    <div class="offcanvas__option">
      <div class="offcanvas__links">
        <a href="#">Sign in</a>
        <a href="#">FAQs</a>
      </div>
      <div class="offcanvas__top__hover">
        <span>Usd <i class="arrow_carrot-down"></i></span>
        <ul>
          <li>USD</li>
          <li>EUR</li>
          <li>USD</li>
        </ul>
      </div>
    </div>
    <div class="offcanvas__nav__option">
      <a href="#" class="search-switch"
        ><img src="img/icon/search.png" alt=""
      /></a>
      <a href="#"><img src="img/icon/heart.png" alt="" /></a>
      <a href="#"><img src="img/icon/cart.png" alt="" /> <span>0</span></a>
      <div class="price">$0.00</div>
    </div>
    <div id="mobile-menu-wrap"></div>
    <div class="offcanvas__text">
      <p>Free shipping, 30-day return or refund guarantee.</p>
    </div>
  </div>
  <!-- Offcanvas Menu End -->

  <!-- Header Section Begin -->
  <header class="header">
    <div class="header__top">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-7">
            <div class="header__top__left">
              <p>Free shipping, 30-day return or refund guarantee.</p>
            </div>
          </div>
          <div class="col-lg-6 col-md-5">
            <div class="header__top__right">
              <div class="header__top__links">
                <a href="#">Sign in</a>
                <a href="#">FAQs</a>
              </div>
              <div class="header__top__hover">
                <span>Usd <i class="arrow_carrot-down"></i></span>
                <ul>
                  <li>USD</li>
                  <li>EUR</li>
                  <li>USD</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3">
          <div class="header__logo">
            <!-- <h3>Simaza Group</h3> -->
            <a href="./index.html"><img src="img/Simaza Logo-01png.png" height="60" alt="" /></a>
          </div>
        </div>
        <div class="col-lg-7 col-md-7">
          <nav class="header__menu mobile-menu">
            <ul>
              <li class=""><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/shop">Shop</a></li>
              <li><a href="/blog">Blog</a></li>
              <li><a href="https://houses.simazagroup.rw/">Houses & Car</a></li>
              <li><a href="/contact">Contacts</a></li>
            </ul>
          </nav>
        </div>
        <div class="col-lg-2 col-md-2">
          <div class="header__nav__option">
            <a href="#" class="search-switch"
              ><img src="img/icon/search.png" alt=""
            /></a>
            <a href="#"><img src="img/icon/heart.png" alt="" /></a>
            <a href="/shopping-cart"
              ><img src="img/icon/cart.png" alt="" />
              <span>{{ subQuantity }}</span></a
            >
            <div class="price">${{ subTotal }}</div>
          </div>
        </div>
      </div>
      <div class="canvas__open"><i class="fa fa-bars"></i></div>
    </div>
  </header>
  <!-- Header Section End -->
</template>
<script>
export default {
  data () {
    return {
      subTotal: '0',
      subQuantity: '0'
    }
  },
  methods: {
    getCarted () {
      this.subTotal = localStorage.getItem('subTotal')
      this.subQuantity = localStorage.getItem('subQuantity')
    }
  },
  mounted () {
    this.getCarted()
  }
}
</script>
